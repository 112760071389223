import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="clipboard"
export default class extends Controller {
  static targets = [ "source", "output" ]
  static classes = [ "supported" ]

  connect() {
    navigator.permissions.query({ name: "clipboard-write" }).then((result) => {
      if (result.state == "granted" || result.state == "prompt") {
        this.element.classList.add(this.supportedClass);
      }
    });
  }

  copy(event) {
    event.preventDefault();

    if (!this.element.classList.contains(this.supportedClass)) {
      return
    }

    let copyText = '';
    if (this.sourceTarget.select) {
      this.sourceTarget.select();
      this.sourceTarget.setSelectionRange(0, 99999);
      copyText = this.sourceTarget.value;
    } else {
      copyText = this.sourceTarget.innerText.trim();
    }

    navigator.clipboard.writeText(copyText);

    if (this.hasOutputTarget) {
      const originalText = this.outputTarget.innerText;
      this.outputTarget.innerText = "Copied!";

      setTimeout(() => {
        this.outputTarget.innerHTML = originalText;
      }, 1000);
    }
  }
}
