import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["toggable", "output"];

  initialize() {
    this.hidden = true;
  }

  showOnChange(event) {
    event.preventDefault();
    const element = event.currentTarget;
    const value = element.value;

    if (element.dataset.hideUnless !== undefined) {
      if(value !== element.dataset.hideUnless) {
        this.toggableTargets.forEach((target) => target.classList.remove("hidden"));
      } else {
        this.toggableTargets.forEach((target) =>
          target.classList.add("hidden")
        );
      }
    }
  }

  toggle(event) {
    event.preventDefault();

    this.hidden = !this.hidden;
    if (this.hasOutputTarget) {
      this.outputTarget.textContent = this.hidden
        ? this.outputTarget.dataset.toggleHidden
        : this.outputTarget.dataset.toggleVisible;
    }

    this.toggableTargets.forEach((target) => target.classList.toggle("hidden"));
  }
}
