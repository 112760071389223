// Entry point for the build script in your package.json
import "./controllers"
import "./shortcuts";
import Rails from "@rails/ujs";
Rails.start();

import "chartkick/chart.js";

window.Tablesort = require("tablesort");
require("tablesort/src/sorts/tablesort.number");
require("tablesort/src/sorts/tablesort.monthname");

document.addEventListener("DOMContentLoaded", (event) => {
  document
    .querySelectorAll("table[data-sortable]")
    .forEach((e) => new Tablesort(e));
});
import "trix"
import "@rails/actiontext"
