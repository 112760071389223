import { Application } from "@hotwired/stimulus"
import { Dropdown, Modal, Popover } from "tailwindcss-stimulus-components";

const application = Application.start()

// Configure Stimulus development experience
application.warnings = true
application.debug    = false
window.Stimulus      = application

application.register("dropdown", Dropdown);
application.register("modal", Modal);
application.register("popover", Popover);

export { application }
