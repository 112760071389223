import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  openInModal(evnt) {
    evnt.preventDefault();

    const url = evnt.target.href || evnt.target.dataset.detailsUrl;
    fetch(url, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => response.text())
      .then(
        (html) => (document.getElementById("modal-container").innerHTML = html)
      )
      .then(() => window.dispatchEvent(new CustomEvent("openModal")))
      .catch((error) => console.log("ERROR", error));
  }

  showInModal(evnt) {
    evnt.preventDefault();

    const target = document.getElementById(evnt.target.dataset.target);

    if (target) {
      document.getElementById("modal-container").innerHTML = target.innerHTML;
      window.dispatchEvent(new CustomEvent("openModal"));
    }
  }
}
