import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "pricePerShare",
    "totalShares",
    "operations",
    "purchasePrice",
    "totalProfit",
  ];
  static values = {
    initialShares: Number,
    initialPrice: Number,
    currentPrice: Number,
    currentContracts: Array,
  };

  formatCurrency(number, options = {}) {
    const {
      precision = 2,
      unit = "$",
      separator = ",",
      delimiter = ".",
      format = "%u%n",
    } = options;

    const formattedNumber = number.toFixed(precision);
    const [integerPart, decimalPart] = formattedNumber.split(".");

    const formattedInteger = integerPart.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      separator
    );

    let result = `${formattedInteger}${delimiter}${decimalPart}`;
    result = format.replace("%u", unit).replace("%n", result);

    return result;
  }

  connect() {
    this.initial = "CURRENT POSITION";
    this.totalShares = 0;
    this.pricePerShare = 0;
    this.operations = [];
    this.totalValue = this.totalShares * this.pricePerShare;
    this.totalProfit =
      this.totalValue - this.initialSharesValue * this.currentPriceValue;

    console.log(this.currentContractsValue);
    this.currentContractsValue.forEach((contract) => {
      this.addOperation(contract);
    });

    this.calculateAveragePricePerShare();
  }

  addOperation(operation) {
    this.operations.push(operation);
    this.calculateAveragePricePerShare();
  }

  createOperation(event) {
    event.preventDefault();
    const form = event.target;
    const type = form.elements.type.value;
    const price = parseFloat(form.elements.price.value);
    const quantity = parseInt(form.elements.quantity.value);

    if (isNaN(price) || isNaN(quantity) || quantity <= 0) {
      alert("Please enter valid price and quantity.");
      return;
    }

    const newOperation = {
      description: `${type} ${quantity} shares at ${this.formatCurrency(
        price
      )}`,
      price,
      quantity,
      adds_stock: type !== "SELL",
      removable: true,
    };

    this.addOperation(newOperation);
    form.reset();
  }

  removeOperation(event) {
    const index = event.params.index;
    if (index >= 0 && index < this.operations.length) {
      const operation = this.operations[index];
      if (operation.removable === false) {
        alert("Cannot remove the initial operation.");
        return;
      }

      this.operations.splice(index, 1);
    }

    this.calculateAveragePricePerShare();
  }

  executeOperation(operation) {
    const { adds_stock, price, quantity } = operation;
    const totalPrice = price * quantity;

    const multiplier = adds_stock ? 1 : -1;

    this.totalShares += multiplier * quantity;
    this.totalValue += multiplier * totalPrice;
  }

  calculateAveragePricePerShare() {
    this.totalShares = 0;
    this.pricePerShare = 0;
    this.totalValue = 0;

    this.operations.forEach((op) => this.executeOperation(op));

    this.totalProfit =
      this.totalShares * this.currentPriceValue - this.totalValue;

    if (this.totalShares > 0) {
      this.pricePerShare = this.totalValue / this.totalShares;
    } else {
      this.pricePerShare = 0;
      this.totalValue = 0;
    }

    this.updateDisplay();
    this.updateOperationsList();
  }

  updateDisplay() {
    this.pricePerShareTarget.textContent = this.formatCurrency(
      this.pricePerShare
    );
    this.totalSharesTarget.textContent = this.totalShares;
    this.purchasePriceTarget.textContent = this.formatCurrency(this.totalValue);
    this.totalProfitTarget.textContent = this.formatCurrency(this.totalProfit);
  }

  updateOperationsList() {
    this.operationsTarget.innerHTML = this.operations
      .map(
        (op, index) => `
        <li class="flex justify-between items-center p-4 rounded-lg !ml-0 !mt-2 first:!mt-0 ${
          op.adds_stock ? "bg-green-100" : "bg-red-100"
        }">
          <span class="${
            op.adds_stock ? "text-green-800" : "text-red-800"
          } font-semibold text-lg">
            ${op.description}
          </span>
          ${
            op.removable
              ? `<button data-action="click->stock-simulation#removeOperation" data-stock-simulation-index-param="${index}" class="text-gray-600 hover:text-gray-800 transition duration-200">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>`
              : ""
          }
        </li>
      `
      )
      .join("");
  }
}
