import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="stock-lots--assignement"
export default class extends Controller {
  static targets = ["template"];

  addLot(evnt) {
    evnt.preventDefault();

    const content = this.templateTarget.innerHTML;
    this.templateTarget.insertAdjacentHTML("beforebegin", content);
  }
}
