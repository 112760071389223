import hotkeys from "hotkeys-js";

hotkeys("s", function (event, handler) {
  event.preventDefault();

  let element = document.getElementById("ticker-filter");
  if (element) {
    element.focus();
    element.setSelectionRange(0, element.value.length);
  }
});

hotkeys("t", function (event, handler) {
  event.preventDefault();

  let element = document.getElementById("main-toggle-btn");
  if (element) {
    element.click();
  }
});

hotkeys("command+b,ctrl+b", function (event, handler) {
  event.preventDefault();

  let elements = document.getElementsByClassName("currency");
  for (let i = 0; i < elements.length; i++) {
    elements[i].classList.toggle("text-private");
  }
});
