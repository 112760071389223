import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="insights"
export default class extends Controller {
  static targets = [ "ticker" ]

  searchWithKeyboard(event) {
    if (event.keyCode == 13 || event.key == 'Enter') {
      this.show()
    }
  }

  show() {
    window.location.href = `/insights/${this.tickerTarget.value}`
  }
}
